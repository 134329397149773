<template>
  <div class="flex h-52 w-48 flex-col items-center justify-center space-y-4 rounded border border-gray-700 p-6">
    <ChatImage custom-class="font-medium" :item="object" size="lg" :show-online="object.users ? false : true" />
    <div class="w-36">
      <div class="flex items-center justify-center space-x-1 text-xs font-medium">
        <span
          class="object-name line-clamp-1 cursor-pointer"
          style="max-width: 144px"
          :title="object.name"
          @click="showObjectInfo"
        >
          {{ object.name }}
        </span>
      </div>
      <div
        v-if="isUser"
        class="object-subtitle line-clamp-1 cursor-pointer text-center text-xs font-medium text-gray-400"
        :title="getUserSubtitle(object)"
      >
        {{ getUserSubtitle(object) }}
      </div>
      <div
        v-else
        class="object-category line-clamp-1 cursor-pointer text-center text-xs font-medium text-gray-400"
        :title="object.category"
      >
        {{ object.category }}
      </div>
    </div>
    <BaseButton type="secondary" size="sm" :busy="buttonBusy" @press="clickAction">Message</BaseButton>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import ChatImage from './ChatImage.vue';
import { getUserSubtitle } from '@/composeables/chat';
import useEmitter from '@/composeables/emitter';

const $emitter = useEmitter();
const props = defineProps({
  object: Object,
  isUser: Boolean
});

const buttonBusy = ref(false);
function clickAction() {
  if (props.isUser) {
    buttonBusy.value = true;
    $emitter.$emit('send-dm', props.object);
  } else {
    $emitter.$emit('show:create-channel-modal', props.object.name);
  }
}
function showObjectInfo() {
  if (props.isUser) {
    $emitter.$emit('show:user-bio', props.object);
  } else {
    $emitter.$emit('show:company-info', props.object);
  }
}
</script>
